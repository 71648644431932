import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { DBProdExtra } from '@core/schemas/db/schema.db.prod';
import { now } from '@core/util/util.timestamp';

export const ProdExtraExample: OmitDBRecord<DBProdExtra>[] = [
  {
    product_id: 12345,
    type: 'QUANTITY',
    timestamp: now(),
    fmc1_value: 123,
    fmc1_timestamp: now(),
    fmc2_value: undefined,
    fmc2_timestamp: undefined,
  },
];
