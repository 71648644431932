import {
  AdministrationTestBOMConstructionEndpoint,
  BOMItem,
} from '@core/schemas/endpoint/schema.endpoint.administration';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY, REGEX_PRODUCT_ID } from '@core/types/types.regex';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showErrorToast } from '@frontend/common/lib/functions';
import { FInput } from '@frontend/form/components/FInput';
import { Table, TableColumn } from '@frontend/table/table';
import { Button, Tabs } from '@lego/klik-ui';
import { useMemo, useState } from 'react';

export function TestBOMConstruction() {
  const [productId, setProductId] = useState('');
  const [loading, setLoading] = useState(false);
  const [bom, setBom] = useState<BOMItem[]>([]);
  const [ignored, setIgnored] = useState<{ material: BOMItem; reason: string }[]>([]);
  const [exploded, setExploded] = useState<BOMItem[]>([]);
  const [overdose, setOverdose] = useState<BOMItem[]>([]);

  async function getConstructedBom() {
    if (!REGEX_PRODUCT_ID.test(productId)) {
      showErrorToast('Not a valid product ID');
      return;
    }

    setLoading(true);
    const [err, bomResult] = await callEndpoint({
      endpoint: AdministrationTestBOMConstructionEndpoint,
      input: { productId: convertProductIdTo8digits(productId) },
      errorHandling: { header: 'Constructing BOM' },
    });
    setLoading(false);

    if (err) {
      setBom([]);
      setIgnored([]);
      setExploded([]);
      setOverdose([]);
      return;
    }

    setBom(bomResult.materials);
    setExploded(bomResult.exploded);
    setIgnored(bomResult.ignored);
    setOverdose(bomResult.overdose);
  }

  const columns: TableColumn<BOMItem>[] = [
    { title: 'Material ID', dataIndex: 'materialId', sorted: 'ascending' },
    { title: 'Description', dataIndex: 'description' },
    { title: 'Quantity', dataIndex: 'quantity', align: 'end' },
    { title: 'Material group', dataIndex: 'materialGroup', align: 'end' },
    { title: 'Material type', dataIndex: 'materialType' },
    { title: 'Make buy mark', dataIndex: 'makeBuy' },
    { title: 'Costing global vendor', dataIndex: 'costingGlobalVendor' },
    { title: 'Version', dataIndex: 'version', width: 'auto' },
  ];

  const columnsIgnore: TableColumn<BOMItem>[] = [
    { title: 'Material ID', dataIndex: 'materialId', sorted: 'ascending' },
    { title: 'Description', dataIndex: 'description' },
    {
      dataIndex: 'reason',
      title: 'Ignore reason',
    },
    { title: 'Quantity', dataIndex: 'quantity', align: 'end' },
    { title: 'Material group', dataIndex: 'materialGroup', align: 'end' },
    { title: 'Material type', dataIndex: 'materialType' },
    { title: 'Make buy mark', dataIndex: 'makeBuy' },
    { title: 'Costing global vendor', dataIndex: 'costingGlobalVendor' },
    { title: 'Version', dataIndex: 'version', width: 'auto' },
  ];

  const ignoreRows = useMemo(
    () => ignored.map((i) => ({ ...i.material, reason: i.reason })),
    [ignored],
  );

  return (
    <div>
      <FInput
        topLabel="Product ID:"
        value={productId}
        regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
        onChange={setProductId}
        align="start"
        style={{ width: 300, marginBottom: 24 }}
        editable
        onEnter={getConstructedBom}
      />
      <Button
        isLoading={loading}
        size="sm"
        onClick={getConstructedBom}
        disabled={!REGEX_PRODUCT_ID.test(productId)}
      >
        Construct
      </Button>
      <div style={{ marginBottom: 16, marginTop: 48 }}>
        <ExportButton
          rows={[...bom, ...overdose, ...exploded, ...ignored.map((i) => i.material)]}
          columns={columns}
        >
          Export all materials
        </ExportButton>
      </div>
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab className="tab-header">Materials ({bom.length})</Tabs.Tab>
          <Tabs.Tab className="tab-header">Overdose ({overdose.length})</Tabs.Tab>
          <Tabs.Tab className="tab-header">Exploded materials ({exploded.length})</Tabs.Tab>
          <Tabs.Tab className="tab-header">Ignored materials ({ignored.length})</Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <Table
              headerContent={<ExportButton rows={bom} columns={columns} />}
              id="test-bom"
              columns={columns}
              rows={bom}
              rowKey="materialId"
              itemType="material"
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Table
              headerContent={<ExportButton rows={overdose} columns={columns} />}
              id="test-overdose"
              columns={columns}
              rows={overdose}
              rowKey="materialId"
              itemType="material"
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Table
              headerContent={<ExportButton rows={exploded} columns={columns} />}
              id="test-bom-exploded"
              columns={columns}
              rows={exploded}
              rowKey="materialId"
              itemType="material"
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Table
              headerContent={<ExportButton rows={ignoreRows} columns={columnsIgnore} />}
              id="test-bom-ignored"
              columns={columnsIgnore}
              rows={ignoreRows}
              rowKey="materialId"
              itemType="material"
            />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>
    </div>
  );
}
