export function now(options?: { omitMilliseconds?: boolean }) {
  let isoString = new Date().toISOString();

  if (options?.omitMilliseconds) {
    isoString = isoString.slice(0, -5) + 'Z';
  }

  return isoString;
}

export function defaultTs() {
  return new Date(0).toISOString();
}
