import { GroupName } from '../../../backend/src/common/types/common.types.groups';

export enum VirtualGroupName {
  Administrators = 'Administrators',
  ElementsUsers = 'Elements users',
  PackagingUsers = 'Packaging users',
  BIUsers = 'BI users',
  FinalCostsUsers = 'Final costs users',
  TheCostingGroup = 'The costing group',
  DisplayAll = 'Display all',
  Reporting = 'Reporting',
}

export const VIRTUAL_GROUPS: {
  id: string;
  name: VirtualGroupName;
  description: string;
  groupNames: GroupName[];
}[] = [
  {
    id: '6',
    description: 'Full access',
    groupNames: ['Administrators'],
    name: VirtualGroupName.Administrators,
  },
  {
    id: '1',
    name: VirtualGroupName.ElementsUsers,
    description:
      'Can use the Elements screen and extract elements costs and elements errors reports',
    groupNames: [
      'User_Elements',
      'User_Reporting_ElementsCosts',
      'User_Reporting_ElementsErrors',
      'Email_Elements',
    ],
  },
  {
    id: '2',
    name: VirtualGroupName.PackagingUsers,
    description: 'Can use the Packaging screen and extract packaging and BI reports',
    groupNames: [
      'User_Packaging',
      'User_Reporting_Packaging',
      'User_Reporting_BI',
      'Email_Packaging',
    ],
  },
  {
    id: '3',
    name: VirtualGroupName.BIUsers,
    description: 'Can use the Instructions screen and extract the BI report',
    groupNames: ['User_BI', 'User_Reporting_BI', 'Email_BI'],
  },
  {
    id: '4',
    name: VirtualGroupName.FinalCostsUsers,
    description: 'Can use all screens and extract all reports',
    groupNames: [
      'User_Elements',
      'User_Packaging',
      'User_BI',
      'User_FinalCosts',
      'User_Reporting_ElementsCosts',
      'User_Reporting_ElementsErrors',
      'User_Reporting_Packaging',
      'User_Reporting_BI',
      'User_Reporting_FinalCosts',
      'User_Reporting_CostingStatus',
    ],
  },
  {
    id: '5',
    name: VirtualGroupName.TheCostingGroup,
    description: 'Can do everything except altering non-rate tables',
    groupNames: [
      'User_BI',
      'User_Elements',
      'User_FinalCosts',
      'User_Packaging',
      'Email_Elements',
      'Email_Packaging',
      'Email_BI',
      'User_Reporting_BI',
      'User_Reporting_Packaging',
      'User_Reporting_ElementsCosts',
      'User_Reporting_ElementsErrors',
      'User_Reporting_FinalCosts',
      'User_Reporting_CostingStatus',
      'AccessManagement',
      'ChangeFMCYear',
      'CreateDeleteProduct',
      'DownloadDataExample',
      'ShowHideColumns',
      'SimulateElementsCalculation',
      'DataAdmin_AMLog_ReadOnly',
      'DataAdmin_BICost_ReadOnly',
      'DataAdmin_BIRate',
      'DataAdmin_BOM_ReadOnly',
      'DataAdmin_Config_ReadOnly',
      'DataAdmin_ElemCost_ReadOnly',
      'DataAdmin_ElemError_ReadOnly',
      'DataAdmin_ElementsScrapRate',
      'DataAdmin_HiddenColumn_ReadOnly',
      'DataAdmin_MARA_ReadOnly',
      'DataAdmin_OHRate',
      'DataAdmin_PackSource_ReadOnly',
      'DataAdmin_PackagingAddOnRate',
      'DataAdmin_PackagingFoilRate',
      'DataAdmin_PackagingNonstandardCost_ReadOnly',
      'DataAdmin_PackagingRate',
      'DataAdmin_PackagingScrapRate',
      'DataAdmin_PackagingStandardCost_ReadOnly',
      'DataAdmin_PackingNonstandardCost_ReadOnly',
      'DataAdmin_PackingNonstandardKey_ReadOnly',
      'DataAdmin_PackingNonstandardRate',
      'DataAdmin_PackingStandardCost_ReadOnly',
      'DataAdmin_PackingStandardFinalPackRate',
      'DataAdmin_PackingStandardPrepackRate',
      'DataAdmin_ProdCost_ReadOnly',
      'DataAdmin_ProdExtra_ReadOnly',
      'DataAdmin_ProdHeadBI_ReadOnly',
      'DataAdmin_ProdHeadElem_ReadOnly',
      'DataAdmin_ProdHeadFC_ReadOnly',
      'DataAdmin_ProdHeadPack_ReadOnly',
      'DataAdmin_ProdParams_ReadOnly',
      'DataAdmin_ProdPPO_ReadOnly',
      'DataAdmin_UserSettings_ReadOnly',
      'DataAdmin_YearChangeRecord_ReadOnly',
    ],
  },
  {
    id: '7',
    name: VirtualGroupName.DisplayAll,
    description: 'Read only access to all pages and can extract all reports',
    groupNames: [
      'User_Elements_ReadOnly',
      'User_Packaging_ReadOnly',
      'User_BI_ReadOnly',
      'User_FinalCosts_ReadOnly',
      'User_Reporting_ElementsCosts',
      'User_Reporting_ElementsErrors',
      'User_Reporting_Packaging',
      'User_Reporting_BI',
      'User_Reporting_FinalCosts',
      'User_Reporting_CostingStatus',
    ],
  },
  {
    id: '8',
    name: VirtualGroupName.Reporting,
    description: 'Can extract all reports',
    groupNames: [
      'User_Reporting_ElementsCosts',
      'User_Reporting_ElementsErrors',
      'User_Reporting_Packaging',
      'User_Reporting_BI',
      'User_Reporting_FinalCosts',
      'User_Reporting_CostingStatus',
    ],
  },
];
