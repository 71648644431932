import { PK } from '@core/types/types.pk';
import { z } from 'zod';
import {
  DBAMLogSchema,
  DBOHRateSchema,
  DBElementsScrapRateSchema,
  DBAMMembershipSchema,
} from '@core/schemas/db/schema.db.common';
import {
  DBPackagingAddonRateSchema,
  DBPackagingFoilRateSchema,
  DBPackSourceSchema,
  DBPackagingRateSchema,
  DBPackagingScrapRateSchema,
  DBPackingStandardPrepackRateSchema,
  DBPackingStandardCostSchema,
  DBPackingStandardFinalPackRateSchema,
  DBPackingNonstandardCostSchema,
  DBPackingNonstandardRateSchema,
  DBProdHeadPackSchema,
  DBPackingNonstandardKeySchema,
  DBPackagingNonstandardCostSchema,
  DBPackagingStandardCostSchema,
} from '@core/schemas/db/schema.db.packaging';
import { DBBICostSchema, DBBIRateSchema, DBProdHeadBISchema } from '@core/schemas/db/schema.db.bi';
import {
  DBBOMSchema,
  DBElemCostSchema,
  DBElemErrorSchema,
  DBProdHeadElemSchema,
} from '@core/schemas/db/schema.db.elements';
import { DBConfigSchema } from '@core/schemas/db/schema.db.config';
import { DBHiddenColumnSchema } from '@core/schemas/db/schema.db.hiddenColumns';
import { DBMaraSchema } from '@core/schemas/db/schema.db.mara';
import {
  DBProdCostSchema,
  DBProdExtraSchema,
  DBProdHeadFCSchema,
  DBProdPPOSchema,
  DBProdParamsSchema,
} from '@core/schemas/db/schema.db.prod';
import { DBUserSettingsSchema } from '@core/schemas/db/schema.db.userSettings';
import { DBYearChangeRecordSchema } from './schema.db.yearChange';

export const PK_TO_SCHEMA: Record<PK, z.ZodObject<any>> = {
  [PK.PackagingAddOnRate]: DBPackagingAddonRateSchema,
  [PK.AMLog]: DBAMLogSchema,
  [PK.AMMembership]: DBAMMembershipSchema,
  [PK.BICost]: DBBICostSchema,
  [PK.BIRate]: DBBIRateSchema,
  [PK.BOM]: DBBOMSchema,
  [PK.Config]: DBConfigSchema,
  [PK.ElemCost]: DBElemCostSchema,
  [PK.ElemError]: DBElemErrorSchema,
  [PK.PackagingFoilRate]: DBPackagingFoilRateSchema,
  [PK.HiddenColumn]: DBHiddenColumnSchema,
  [PK.MARA]: DBMaraSchema,
  [PK.OHRate]: DBOHRateSchema,
  [PK.PackingNonstandardKey]: DBPackingNonstandardKeySchema,
  [PK.PackingStandardPrepackRate]: DBPackingStandardPrepackRateSchema,
  [PK.PackSource]: DBPackSourceSchema,
  [PK.PackingStandardCost]: DBPackingStandardCostSchema,
  [PK.PackingStandardFinalPackRate]: DBPackingStandardFinalPackRateSchema,
  [PK.PackagingStandardCost]: DBPackagingStandardCostSchema,
  [PK.PackagingNonstandardCost]: DBPackagingNonstandardCostSchema,
  [PK.PackagingRate]: DBPackagingRateSchema,
  [PK.PackagingScrapRate]: DBPackagingScrapRateSchema,
  [PK.PackingNonstandardCost]: DBPackingNonstandardCostSchema,
  [PK.PackingNonstandardRate]: DBPackingNonstandardRateSchema,
  [PK.ProdCost]: DBProdCostSchema,
  [PK.ProdExtra]: DBProdExtraSchema,
  [PK.ProdHeadBI]: DBProdHeadBISchema,
  [PK.ProdHeadElem]: DBProdHeadElemSchema,
  [PK.ProdHeadFC]: DBProdHeadFCSchema,
  [PK.ProdHeadPack]: DBProdHeadPackSchema,
  [PK.ProdParams]: DBProdParamsSchema,
  [PK.ProdPPO]: DBProdPPOSchema,
  [PK.ElementsScrapRate]: DBElementsScrapRateSchema,
  [PK.UserSettings]: DBUserSettingsSchema,
  [PK.YearChangeRecord]: DBYearChangeRecordSchema,
};
