import { PK } from '@core/types/types.pk';
import { PackagingAddOnRateExample } from './PackagingAddonRate.example';
import { BICostExample } from './BICost.example';
import { BIRateExample } from './BIRate.example';
import { ElemCostExample } from './ElemCost.example';
import { ElemErrorExample } from './ElemError.example';
import { PackagingFoilRateExample } from './PackagingFoilRate.example';
import { MARAExample } from './MARA.example';
import { OHRateExample } from './OHRate.example';
import { PackagingNonstandardCostExample } from './PackagingNonstandardCost.example';
import { PackagingRateExample } from './PackagingRate.example';
import { PackagingScrapRateExample } from './PackagingScrapRate.example';
import { PackingStandardPrepackRateExample } from './PackingStandardPrepackRate.example';
import { PackingStandardCostExample } from './PackingStandardCost.example';
import { PackingStandardFinalPackRateExample } from './PackingStandardFinalPackRate.example';
import { PackSourceExample } from './PackSource.example';
import { PackingNonstandardCostExample } from './PackingNonstandardCost.example';
import { PackingNonstandardKeyExample } from './PackingNonstandardKey.example';
import { PackingNonstandardRateExample } from './PackingNonstandardRate.example';
import { ProdCostExample } from './ProdCost.example';
import { ProdHeadBIExample } from './ProdHead_BI.example';
import { ProdHeadElemExample } from './ProdHead_Elem.example';
import { ProdHeadFCExample } from './ProdHead_FC.example';
import { ProdHeadPackExample } from './ProdHead_Pack.example';
import { ProdParamsExample } from './ProdParams.example';
import { ProdPPOExample } from './ProdPPO.example';
import { ElementsScrapRateExample } from './ElementsScrapRate.example';
import { BOMExample } from './BOM.example';
import { AMLogExample } from './AMLog.example';
import { UserSettingsExample } from './UserSettings.example';
import { PackagingStandardCostExample } from './PackagingStandardCost.example';
import { AMMembershipExample } from './AMMemberhip.example';
import { YearChangeRecordExample } from './YearChangeRecord.example';
import { ProdExtraExample } from './ProdExtra.example';

export const EXAMPLE: Record<PK, Record<string, unknown>[]> = {
  [PK.AMLog]: AMLogExample,
  [PK.AMMembership]: AMMembershipExample,
  [PK.BICost]: BICostExample,
  [PK.BIRate]: BIRateExample,
  [PK.BOM]: BOMExample,
  [PK.Config]: [],
  [PK.ElemCost]: ElemCostExample,
  [PK.ElemError]: ElemErrorExample,
  [PK.PackagingFoilRate]: PackagingFoilRateExample,
  [PK.HiddenColumn]: [],
  [PK.MARA]: MARAExample,
  [PK.OHRate]: OHRateExample,
  [PK.PackagingAddOnRate]: PackagingAddOnRateExample,
  [PK.PackagingStandardCost]: PackagingStandardCostExample,
  [PK.PackagingNonstandardCost]: PackagingNonstandardCostExample,
  [PK.PackagingRate]: PackagingRateExample,
  [PK.PackagingScrapRate]: PackagingScrapRateExample,
  [PK.PackingStandardPrepackRate]: PackingStandardPrepackRateExample,
  [PK.PackingStandardCost]: PackingStandardCostExample,
  [PK.PackingStandardFinalPackRate]: PackingStandardFinalPackRateExample,
  [PK.PackSource]: PackSourceExample,
  [PK.PackingNonstandardCost]: PackingNonstandardCostExample,
  [PK.PackingNonstandardKey]: PackingNonstandardKeyExample,
  [PK.PackingNonstandardRate]: PackingNonstandardRateExample,
  [PK.ProdCost]: ProdCostExample,
  [PK.ProdExtra]: ProdExtraExample,
  [PK.ProdHeadBI]: ProdHeadBIExample,
  [PK.ProdHeadElem]: ProdHeadElemExample,
  [PK.ProdHeadFC]: ProdHeadFCExample,
  [PK.ProdHeadPack]: ProdHeadPackExample,
  [PK.ProdParams]: ProdParamsExample,
  [PK.ProdPPO]: ProdPPOExample,
  [PK.ElementsScrapRate]: ElementsScrapRateExample,
  [PK.UserSettings]: UserSettingsExample,
  [PK.YearChangeRecord]: YearChangeRecordExample,
};
